import React from 'react'
import { THEME_BG_COLOR, playstore } from '../lib/config'
import * as Config from "../lib/config";
import { Adsense } from '@ctrl/react-adsense';

const footer = (props) => {
  const { handleChange } = props;
  const goto = () => {
    console.log('slmdlknsd');
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  return (
    <>
        <div className='FooterMainSection'>
            <div className='container'>
                <div className='row px-4 pt-4 pb-md-4 mt-5'>
                        <div className='col-12 col-sm-12 col-md-10'>
                            <div className='d-flex pb-3'>
                                <div className='col-auto pl-0 FooterLogo'>
                                    <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.james.ledscroller.ledbanner.ledsign&hl=en&gl=US'>
                                        <img
                                        src={Config.images.ledIcon}
                                        alt="right icon"
                                        width="100%"
                                        className='img-fluid AppIcons'
                                        />
                                    </a>
                                </div>
                                <div className='col pr-0 appName'>
                                    <div>LED BANNER</div>
                                </div>
                            </div>
                            <div className='footerDiscription'>
                                LED Banner is an easy-to-use LED scroller - Led Banner, just enter Text or Emoji to personalize your message, and then decide it be scrolling text.
                                Turn your device into a LED Scrolling Marquee/LED signboard.
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 footerLinkSection row m-0' onClick={() => goto()}>
                            <div className='col-12 p-0 mobile-border-bottom mobile-border-top'>
                                <div className='web-border-bottom footerLinks m-auto ml-md-0 mr-md-auto '>Links</div>
                            </div>
                            <div className='footerLinks py-2 py-md-0  pr-2 col-6 pl-0 col-md-12 ' onClick={(event) => handleChange(event, 3)}>
                                <label target="#" rel="noreferrer" className='footerAcnher web-border-bottom '>
                                About
                                </label>
                            </div>
                            <div className=' footerLinks py-2 py-md-0  pr-2 col-6 pl-0 col-md-12' onClick={(event) => {
                                handleChange(event, 4);
                                goto();
                            }}>
                                <label target="#" rel="noreferrer" className='footerAcnher web-border-bottom '>
                                Privcay
                                </label>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div className='FooterCopyright text-center'>
            <p>Copyright © 2023 ledbanner.com All Rights Reserved</p>
        </div>
        {/* <Adsense
            client="ca-pub-8840218440988032"
            format='auto'
            responsive='true'
            className="adsbygoogle"
            slot="9687309138"
            style={{ height: '300px', display:'block' }}
        /> */}
    </>
  )
}

export default footer

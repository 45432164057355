import React from 'react'

const about = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='commanSmallFontHeading col-12 text-left containSection'>About ledscroller.com</div>
                <div className='col-12 containSection'>
                    We make Scrolling text tools saving you time and effort. We also make money by showing you ads. We don't collect any personal information and we don't share any information with third parties.
                </div>
                <div className='col-12 text-left'>
                    <div className='containSection'>
                        <div className='titleSection'>Our privacy policy : -</div>
                        <div>
                            We will not use or share your information with anyone except as described in this Privacy Policy. We use your personal information for providing and improving Sign Board : Text Animation App. By using Sign Board : Text Animation App, you agree to the collection and use of your information in accordance with this policy. Information collection and use while using Sign Board : Text Animation App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, phone number, postal address (“Personal Information”).
                        </div>
                    </div>
                    <div className='containSection'>
                        <div className='titleSection'>Log Data : - </div>
                        <div>
                            We collect information that your browser sends
                            whenever you visit Sign Board : Text Animation App (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) ad Sign Board : Text Animation App Sign Board : Text Animation App that you visit, the time and date of your visit, the time spent on those pages and other statistics. Cookies Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive. We use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is
                        </div>
                        <div>
                            being sent. However, if you do not accept cookies, you may not be able to use
                            some portions of  ledscroller.com
                        </div>
                    </div>
                    <div className='containSection'>
                        <div className='titleSection'>
                            Mail id : -
                        </div>
                        <div>
                            For the full Terms and Conditions, 
                        </div>
                        <div>
                            Please Contact US : gksolution1122@gmail.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>{`
            .containSection{
                padding-bottom: 25px;   
            }
            .titleSection{
                font-size: 1.1rem;
                font-weight: 600;
                margin-bottom: 10px;
            }
        `}</style>
    </>
  )
}

export default about
import React from 'react';
import Marquee from "react-fast-marquee";
import * as Config from "../lib/config";

const fullScreen = (props) => {
  const { finalResult, RGB, onClose, fontSize, speed, name, isblink, bgColor, color, isMobile, isFlipText, blinkSpeend } = props;
  return (
    <div>
      <div className='fullscreen-section pt-0'>
          <div className="col-xl-12 fullScreenBox col-sm-12">
              <div
                width="100%"
                className='overlayScreenFullImage'
              ></div>
              <Marquee width="100%" direction="left" gradient={false} gradientColor={[RGB.r, RGB.g, RGB.b]} className={`fullscreenSlider ${isblink ? `blink_me` : ""} ${isFlipText ? `mirrorText` : ""}`}  speed={speed * 5}>
                {isMobile ? (
                  <div>
                    <span>{`  \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`}</span>
                    <span className='fullScreenSliderFontSize'>{name || "Enter Your Text"}</span>
                  </div>
                ) : (
                  <div>
                    <span>{` \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`}</span>
                    <span className='fullScreenSliderFontSize'>{name || "Enter Your Text"}</span>
                  </div>
                )}
              </Marquee>
          </div>
      </div>
      <div className="rotateDivice">
        <img src={Config.images.turnDevice} 
          className="turnDevice img-fluid"
        />
        <div className='goBackOption d-flex align-items-center ml-2' onClick={onClose}>
              <span className='goBackIcon p-1 d-flex align-items-center'>
                  <img
                      src={Config.images.leftArrow}
                      alt="right icon"
                      width="100%"
                      className='img-fluid'
                  />
              </span>
              <span className='pl-2'>
                  GO BACK
              </span>
          </div>
      </div>
      <style>{`

          .rotateDivice{
            height: 100vh;
            display: block;
          }
          .rotateDivice img{
            height: 100%;
            width: 100%;
          }

          .goBackIcon{
            width: 24px;
            cursor: pointer;
          }

          .blink_me {
            animation: blinker ${Math.abs(blinkSpeend)}0ms linear infinite;
          }

          .fullscreen-section{
            display: none;
          }

          .mirrorText {
            transform: scale(-1, 1);
          }

          @media screen  and (max-width: 9000px) and (orientation : landscape) {
            .righterBox{
              display: none!important;
            }
            .rotateDivice{
              display: none!important;
            }
            .fullscreen-section{
              display: block;
            }
          }

          .fullScreenBox{
              width: 100%;
              // height: calc(100vh - 100px);
              height: 100vh;
              border-radius: 12px;
              color: ${color};
              background-color: ${bgColor};
              position: relative;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              display: flex;
              overflow: auto;
              // font-size: ${fontSize * 2}px;
            }
  
            .fullScreenSliderFontSize{
              font-size: ${isMobile ? '320' : '520'}px;
              font-weight: 900;
              // font-size: ${isMobile ? fontSize * 46.6 : fontSize * 9}px;
            }
  
            .fullscreenSlider .marquee{
              padding-bottom: ${isMobile ? '30' : '50'}px;
            }
  
            .marquee-container{
              overflow-y: hidden;
            }
  
            .overlayScreenFullImage{
              position: relative;
              height: 100vh;
              // height: calc(100vh - 100px);
              background: url('${Config.images.screenImage}');
              background-repeat: repeat;
              background-attachment: inherit;
              background-size: 100%;
              background-position: center;
              overflow: auto;
              width: 100%;
              position: absolute;
              z-index: 99;
              // border: 1px solid #fff;
              // border-radius: 12px;
            }
  
            .goBackOption{
              color: #fff;
              padding: 8px 15px;
              width: fit-content;
              border: 1px solid #fff;
              border-radius: 10px;
              margin-bottom: 10px;
              cursor: pointer;

              position: absolute;
              z-index: 999;
              left: 50%;
              top: 81%;
              transform: translate(-50%, 50%);
            }
  
          `}</style>
    </div>
  )
}

export default fullScreen;
import React from 'react'
import * as Config from "../../lib/config";

const arr = [1,2,3,4,5,6]
const Template = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                {arr.map((data, index) => (
                    <div className='col-12 col-md-6 pt-3 px-3' key={index}>
                        <div className='videoContainer'>
                            <div className='videoSection position-relative'>
                                <div>
                                    <img
                                        src={Config.images.commingSoon}
                                        alt="right icon"
                                        width="100%"
                                        className='img-fluid commingImg'
                                    />
                                </div>
                                <div className='videoTitleNo'>Video {data}</div>
                            </div>
                            <div className='d-flex justify-content-center pt-4'>
                                <div className='shareButton col-8 col-md-4'>
                                    <img
                                        src={Config.images.externalLink}
                                        alt="right icon"
                                        width="100%"
                                        className='SharebuttonIcon img-fluid'
                                    />
                                    <div>Share</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <style>{`
            .videoTitleNo{
                position: absolute;
                top: 15px;
                right: 20px;
                background: #ffffffbd;
                padding: 5px 10px;
                color: ${Config.THEME_COLOR};
                border-radius: 10px;
                font-weight: 700;
            }
            .videoContainer{
                background: #000000;
                border-radius: 10px;
                padding: 20px;
            }
            .commingImg{
                border-radius: 10px;
            }
            .shareButton{
                display: flex;
                align-items: center;
                padding: 5px 15px;
                background:#818181;
                border-radius: 10px;
                font-size: 21px;
                cursor: pointer;
                justify-content: center;
            }
            // .shareButton:hover {
            //     background: #000000;
            //     border: 1px solid ${Config.THEME_COLOR};
            //     color: #ffffff;
            //     font-size: 21px;
            //     padding: 5px 15px;
            // }
            .SharebuttonIcon{
                height: 40px;
                width: 40px;
                padding: 8px;
            }

              
            @media screen and (max-width: 740px) {
                .videoContainer {
                    padding: 5px 5px 15px 5px;
                }
                .shareButton {
                    padding: 0px 0px;
                    font-size: 17px;
                }
                .SharebuttonIcon{
                    height: 35px;
                    width: 35px;
                    padding: 8px;
                }
                .shareButton:hover {
                    font-size: 17px;
                    padding: 0px 0px;
                }
                .videoTitleNo{
                    top: 7px;
                    right: 7px;
                    font-size: 11px;
                }
            }
              
        `}</style>
    </>
  )
}

export default Template
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import FullScreen from './container/fullScreen';
import './App.css';
import './css/button.css';
import './css/comman.css';
import './css/toggle.css';
import CustomizedTabs from './components/tabs';
import Home from './container/homes/home';
import Template from './container/tamplate/index.jsx';
import Application from './container/application';
import About from './container/staticPage/about';
import PrivacyPolicy from './container/staticPage/privacyPolicy';
import ScrollToTop from './container/dem2';

function App() {
  const [color, setColor] = useState('#FFFF00');
  const [name, setName] = useState('');
  const [speed, setSpeed] = useState(30);
  const [isFlipText, setIsFlipText] = useState(false);
  const [blinkSpeend, setBlinkSpeend] = useState(30);
  const [isFullcreen, setisFullcreen] = useState(false);
  const [fontSize, setfontSize] = useState(85);
  const [isblink, setIsblink] = useState(true);
  const [bgColor, setbgColor] = useState('#000000');
  // const [deviceType, setDeviceType] = useState("");
  const [finalResult, setfinalResult] = useState({
    name: '',
    color: '#FFFFFF',
    bgColor: '#5C667A',
    speed: ''
  });
  const [RGB, setRGB] = useState({
    r: 92,
    g: 102,
    b: 122
  });

  // const handleSubmit = () => {
  //   setfinalResult({
  //     name: name,
  //     color: color,
  //     bgColor: bgColor,
  //     speed: speed,
  //     r: RGB.r,
  //     g: RGB.g,
  //     b: RGB.b
  //   });
  // }

  // useEffect(() => {
  //   let hasTouchScreen = false;
  //   if ("maxTouchPoints" in navigator) {
  //     console.log('hasTouchScreenhasTouchScreen 2');
  //     hasTouchScreen = navigator.maxTouchPoints > 0;
  //   } else if ("msMaxTouchPoints" in navigator) {
  //     hasTouchScreen = navigator.msMaxTouchPoints > 0;
  //   } else {
  //     const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
  //     if (mQ && mQ.media === "(pointer:coarse)") {
  //       hasTouchScreen = !!mQ.matches;
  //     } else if ("orientation" in window) {
  //       hasTouchScreen = true; // deprecated, but good fallback
  //     } else {
  //       // Only as a last resort, fall back to user agent sniffing
  //       var UA = navigator.userAgent;
  //       hasTouchScreen =
  //         /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
  //         /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
  //     }
  //     console.log('hasTouchScreenhasTouchScreen', hasTouchScreen);
  //   }
  //   if (hasTouchScreen) {
  //     setDeviceType(true);
  //   } else {
  //     setDeviceType(false);
  //   }
  // }, []);

  const callVibrate = () => {
    // enable vibration support
    navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

    if (navigator.vibrate) {
      // vibration API supported
      navigator.vibrate(20);
    }
  }

  const selectAnimation = ({ target }) => {
    setSpeed(target.value);
  }
  const handleFullScreen = () => {
    setTimeout(() => {

      setisFullcreen(!isFullcreen);
    }, 500);
  }

  const handlBgcolor = (color) => {
    setbgColor(color);
    const r = parseInt(color.substr(1, 2), 16)
    const g = parseInt(color.substr(3, 2), 16)
    const b = parseInt(color.substr(5, 2), 16)
    setRGB({
      r: r,
      g: g,
      b: b
    });
  }

  const handleShareUrl = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "LED Scroller - LED Banner",
          text: "Hello, please come visit my website  \n https://ledscroller.com/   \n \n Android App: https://play.google.com/store/apps/details?id=com.james.ledscroller.ledbanner.ledsign",
          url: "",
        })
        .then(() => {
          // console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong", error);
        });
    }
    callVibrate();
  };

  const handleSpeedChanger = (val) => {
    //console.log('valvalval', val);
    setSpeed(val);
  };

  const handleBlinkSpeedChanger = (val) => {
    //console.log('valvalval', val);
    setBlinkSpeend(val);
  };


  const pluseFonthandler = () => {
    if (isMobile) {
      if (fontSize < 100) {
        setfontSize(fontSize + 1);
      }
      callVibrate();
    } else {
      if (fontSize < 100) {
        setfontSize(fontSize + 1);
      }
      callVibrate();
    }
  }

  const minusFonthandler = () => {
    if (isMobile) {
      if (fontSize > 12) {
        setfontSize(fontSize - 1);
      }
      callVibrate();
    } else {
      if (fontSize > 30) {
        setfontSize(fontSize - 1);
      }
      callVibrate();
    }
  }

  const handleBlinkToggle = (e) => {
    setIsblink(!isblink);
  }

  const onCycleComplete = (data) => {
    //console.log('funncncncnc 2', data);

  }
  //console.log('deviceTypedeviceType', isMobile);
  return (
    <div className="App">
      {isFullcreen ? (
        <FullScreen
          onClose={handleFullScreen}
          fontSize={fontSize}
          name={name}
          isMobile={isMobile}
          speed={speed}
          RGB={RGB}
          isblink={isblink}
          blinkSpeend={blinkSpeend}
          isFlipText={isFlipText}
          bgColor={bgColor}
          setName={setName}
          color={color}
          finalResult={finalResult}
        />
      ) : (
        <div>
          <CustomizedTabs
            tabs={[{label: 'HOME'}, {label: 'TEMPLATE'}, {label: 'APPLICATION'}]}
            tabContent={[
              {
                content: <Home
                onCycleComplete={onCycleComplete}
                RGB={RGB}
                isblink={isblink}
                speed={speed}
                setIsFlipText={setIsFlipText}
                isFlipText={isFlipText}
                name={name}
                setName={setName}
                handleBlinkToggle={handleBlinkToggle}
                minusFonthandler={minusFonthandler}
                fontSize={fontSize}
                pluseFonthandler={pluseFonthandler}
                color={color}
                blinkSpeend={blinkSpeend}
                setColor={setColor}
                bgColor={bgColor}
                handlBgcolor={handlBgcolor}
                handleSpeedChanger={handleSpeedChanger}
                handleBlinkSpeedChanger={handleBlinkSpeedChanger}
                handleFullScreen={handleFullScreen}
                handleShareUrl={handleShareUrl}
                />
              },
              {
                content: <Template />
              },
              {
                content: <Application />
              },
              {
                content: <About />
              },
              {
                content: <PrivacyPolicy />
              }
            ]}
          />
        </div>
      )}
    </div>

  );
}

export default App;

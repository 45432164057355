import React from 'react'

const staticIntroduction = (props) => {
  const { heading, discription1, discription2, metaData = [] } = props;
  return (
    <>
    <div className='row'>
        <div className='commanSmallFontHeading col-12 text-left'>{heading}</div>
        <div className='col-12 text-left'>
            {metaData.length > 0 && metaData.map((item, index) => (
              <div className='py-2' key={index}>{item}</div>
            ))}
            {/* {discription1 && <div className='py-2'>{discription1}</div>}
            {discription2 && <div className='py-2'>{discription2}</div>} */}
        </div>
    </div>
    </>
  )
}

export default staticIntroduction
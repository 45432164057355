import React from 'react'
import * as Config from "../../lib/config";
import BlurEffectCom from '../../components/blurEffect';

const textInputSection = (props) => {
    const {
        name,
        setName,
        handleFullScreen,
        handleShareUrl
    } = props
  return (
    <>
        <div className='row py-4'>
            <div className='col-12 col-sm-12 col-md-6'>
                <div className="h-100 w-100">
                  {/*<BlurEffectCom
                            size="40px"
                            FontSize="12px"
                        />
                   <label className="font-16 col-auto pl-0 pl-lg-3">Enter Text: </label> */}
                    {/* <div className="col p-0 h-100"> */}
                    <input type="text" className="inputField " placeholder='Enter Your Text' value={name} onChange={e => setName(e.target.value)} />
                    {/* </div> */}
                </div>
            </div>
            <div className='col-12 col-sm-12  pt-4 col-md-3 pt-md-0 '>
                <button className="glow-on-hover w-100 centerContain" type="button" onClick={handleShareUrl}>
                  <img
                  src={Config.images.externalLink}
                  alt="right icon"
                  width="100%"
                  className='buttonIcon img-fluid'
                />
                  Want Share!
                </button>
              {/* <div className='col-12 col-sm-12 col-md-6 pr-0 pl-0 pl-md-2 pt-3 pt-md-0'>
                <button className="reOrderBtn w-100 h-100" type="button">
                <img
                  src={Config.images.downlaodIcon}
                  alt="right icon"
                  width="100%"
                  className='buttonIcon img-fluid'
                />
                Recorder!
                </button>
              </div> */}
            </div>
            <div className='col-12 col-sm-12 pt-4 col-md-3 pt-md-0 '>
                <div className="btnContainer" onClick={handleFullScreen}>
                    <div className="centerContain">
                        <button className="nextBtn">
                        {/* <BlurEffectCom
                            size="40px"
                            FontSize="12px"
                        /> */}
                            {/* <svg width="100%" height="60px" viewBox="0 0 180 60" className="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
                        </svg> */}
                            <span className='d-flex'>
                                <div className='fullScreenIcon'>
                                    <img
                                        src={Config.images.fullScreenIcon}
                                        alt="right icon"
                                        width="100%"
                                        className='img-fluid'
                                    />
                                </div>
                                <span className='pl-3'>FULL SCREEN</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <style>{`
         @media screen and (max-width: 740px) {
          .centerContain {
            width: 100%;
            height: 40px;
            /* position: absolute; */
          }

          .nextBtn {
            min-height: 40px;
          }
        }
        `}</style>
    </>
  )
}

export default textInputSection

import downArrow from '../static/down-arrow.png';
import rightArrow from '../static/rightIcon.png';
import leftArrow from '../static/leftIcon.png';
import screenImage from '../static/background2.png';
import playstore from '../static/playstore.png';
import appstore from '../static/appstore.png';
import turnDevice from '../static/turnDevice.jpeg';

import fullScreenIcon from '../static/full-screen-arrow-icon.svg';
import chevronRight from '../static/chevron-right.svg';
import downlaodIcon from '../static/download-file.svg';
import externalLink from '../static/externalLink.svg';
import partyHatIcon from '../static/party-hat.svg';
import importIcon from '../static/import-icon.svg';
import heartIcon from '../static/heart-icon.svg';
import ledIcon from '../static/ledIcon.png';
import applicationIcon from '../static/applicationIcon.png';
import commingSoon from '../static/commingSoon.jpg';
import emojiAppVideo from '../static/emojiAppVideo.mp4';
import development from '../static/icons8-settings-150.svg';

export const images = {
    downArrow,
    turnDevice,
    downlaodIcon,
    leftArrow,
    rightArrow,
    screenImage,
    playstore,
    appstore,
    chevronRight,
    fullScreenIcon,
    externalLink,
    partyHatIcon,
    importIcon,
    heartIcon,
    ledIcon,
    applicationIcon,
    commingSoon,
    emojiAppVideo,
    development
}

export const THEME_COLOR = '#635ee7';
export const THEME_BG_COLOR = '#1a1b1f';
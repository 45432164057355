import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { THEME_BG_COLOR, THEME_COLOR } from '../../lib/config';
import Footer from '../../container/footer';
import ScrollToTop from '../../container/dem2';

// const AntTabs = withStyles({
//   root: {
//     borderBottom: '1px solid #e8e8e8',
//   },
//   indicator: {
//     backgroundColor: '#1890ff',
//   },
// })(Tabs);

// const AntTab = withStyles((theme) => ({
//   root: {
//     textTransform: 'none',
//     minWidth: 72,
//     fontWeight: theme.typography.fontWeightRegular,
//     marginRight: theme.spacing(4),
//     fontFamily: [
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(','),
//     '&:hover': {
//       color: '#40a9ff',
//       opacity: 1,
//     },
//     '&$selected': {
//       color: '#1890ff',
//       fontWeight: theme.typography.fontWeightMedium,
//     },
//     '&:focus': {
//       color: '#40a9ff',
//     },
//   },
//   selected: {},
// }))((props) => <Tab disableRipple {...props} />);
function TabContainer(props) {
    return <Typography component="div">{props.children}</Typography>;
}
  
const StyledTabs = withStyles({
  root: {
    position: 'sticky',
    top: '0px',
    zIndex: '9999',
    backgroundColor: THEME_BG_COLOR,
    paddingBottom: '5px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 88,
      width: '100%',
      backgroundColor: THEME_COLOR
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(2),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: THEME_BG_COLOR,
  },
}));

export default function CustomizedTabs(props) {
  const { tabs, tabContent } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0 ,0);
  };
console.log('slmlkms===>', value);
  return (
    <div className={classes.root}>
      {/* <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Tab 1" />
          <AntTab label="Tab 2" />
          <AntTab label="Tab 3" />
        </AntTabs>
        <Typography className={classes.padding} />
      </div> */}
      <div className={classes.demo2}>
        <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" centered={true}>
            {tabs?.map((tab, index) => (
                <StyledTab label={tab.label} key={index} />
            ))}
        </StyledTabs>
        <Typography className={classes.padding} >
            {tabContent?.map((tabContent, key) =>
                key === value ? <TabContainer key={key}>{tabContent.content}</TabContainer> : ""
            )}
        </Typography>
      </div>
          <ScrollToTop />
      <Footer
        handleChange={handleChange}
      />
    </div>
  );
}
import React, { useEffect } from "react"

const GoogleFixedAds = (props) => {
    useEffect(() => {
        const pushAd = () => {
            try {
                const adsbygoogle = window.adsbygoogle
                console.log('adsbygoogle', { adsbygoogle })
                adsbygoogle.push({})
            } catch (e) {
                console.error(e)
            }
        }

        let interval = setInterval(() => {
            // Check if Adsense script is loaded every 300ms
            if (window.adsbygoogle) {
                pushAd()
                // clear the interval once the ad is pushed so that function isn't called indefinitely
                clearInterval(interval)
            }
        }, 300)

        return () => {
            clearInterval(interval)
        }
    }, [])
    
    return (
        <>
            <ins
                className="adsbygoogle"
                data-adtest="on"
                layout={props.layout || ''}
                // style={{display:"block", textAlign:'center'}}
                data-ad-client={props.client || 'ca-pub-8840218440988032'}
                data-ad-slot={props.slot}
                data-ad-format={props.format || 'auto'}
                data-full-width-responsive="true"
            ></ins>
        </>
    )
}

export default GoogleFixedAds


import React from 'react'
import SpeedIcon from '@material-ui/icons/Speed';
// import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import InputSlider from '../../components/slider';
import GoogleFixedAds from '../../components/googleAds/GoogleFixedads';
import * as Config from "../../lib/config";
import { isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import StaticIntroduction from './staticIntroduction'
import TextSliderSection from './textSliderSection'
import TextInputSection from './textInputSection'
import { Adsense } from '@ctrl/react-adsense';
import BlurEffectCom from '../../components/blurEffect';

const metaDiscription =[
  "Our user-friendly web app allows you to effortlessly create endless scrolling text and even include emojis!",
  "Simply enter your desired text into the message box, and watch it come to life on a captivating LED display.",
  "The 'LED Scroller' All Device is the latest release that aims to enhance the impact of your events or advertisements.",
  "With 'LED Scroller', you have access to a dynamic web platform that provides an interactive LED display, allowing you to showcase your desired text, images, and messages like a vibrant LED banner. This feature enables users to create vivid and engaging presentations for their events or promotional campaigns.",
  "We prioritize aesthetics and design sensibility over flashy features, ensuring that your LED display stands out. Here's what you can do:",
  "Choose an attractive shape for your sign to create a visually appealing display.",
  "Plan the layout of your sign to optimize the presentation of your content.",
  "Select fonts that suit your style and enhance readability.",
  "Pick visually pleasing colors that capture attention and complement your message.",
  "Add eye-catching graphics to your signage to further enhance its visual appeal.",
  "Apply the blink effect to your text, creating a dynamic and attention-grabbing display.",
  "With these customizable options, you can create stunning LED displays that leave a lasting impression.",
]

const metaIntroduction = [
  "LED Advertising Scrolling Subtitles is an exceptional tool with a diverse range of applications and an aesthetically pleasing interface. This tool serves as an official version, making it well-suited for families, schools, and companies to seamlessly display work-related content and household reminders on their computers in a continuous loop.",
  "LED Scrolling Subtitles is an incredibly fascinating software designed to provide assistance and streamline various tasks."
]

const moreAbout = [
  "LED Scroller: LED Banner Maker is a versatile and user-friendly application designed to create captivating LED banners and scrolling text. Whether you're organizing an event, promoting a product, or simply want to grab attention with a dynamic display, this app has got you covered. With a wide range of features and customization options, you can unleash your creativity and make your messages shine.",
  "One of the key highlights of LED Scroller: LED Banner Maker is its intuitive interface. The app is designed to be user-friendly, ensuring that even those with minimal technical knowledge can easily navigate and create stunning LED banners. Upon launching the app, you'll be greeted with a clean and straightforward layout, making it simple to get started.",
  "To begin creating your LED banner, you can enter your desired text into the message box. The app allows you to input text of any length, providing ample space to convey your message effectively. Moreover, LED Scroller: LED Banner Maker goes beyond ordinary text by allowing you to include emojis as well. This adds a touch of fun and personality to your banners, making them more engaging and eye-catching.",
  "Once you've entered your text, the app instantly generates a preview of how your LED banner will look. This real-time preview feature enables you to make immediate adjustments and see the results in action. You can experiment with different fonts, colors, and sizes to find the perfect combination that suits your style and captures attention. LED Scroller: LED Banner Maker offers a wide range of font options, ensuring that you can find the right style to match your message.",
  "In addition to text customization, the app provides several other tools to enhance the visual appeal of your LED banner. You can choose from various shapes and layouts to create a visually appealing design. Whether you prefer a simple rectangular banner or a more unique shape, the app gives you the freedom to experiment and create a banner that stands out.",
  "Color selection is another crucial aspect of designing an impactful LED banner, and LED Scroller: LED Banner Maker offers a rich palette of colors to choose from. You can select vibrant hues that capture attention or opt for more subtle shades that convey a sense of elegance. With the color options available, you can ensure that your LED banner aligns with your brand or event theme.",
  "To further enhance the visual impact, LED Scroller: LED Banner Maker allows you to add eye-catching graphics to your LED banner. These graphics can be used to accentuate your message, highlight key information, or add a touch of creativity. The app provides a library of pre-designed graphics, or you can even import your own images for a truly personalized touch.",
  "Another noteworthy feature of LED Scroller: LED Banner Maker is the ability to apply various effects to your text. The app allows you to make your text blink, creating a dynamic and attention-grabbing display. This effect is particularly effective when used sparingly and strategically, drawing viewers' eyes to your banner and ensuring that your message doesn't go unnoticed.",
  "Once you've designed your LED banner to perfection, LED Scroller: LED Banner Maker offers multiple ways to showcase and share your creation. You can save the banner as an image or animated GIF directly to your device, allowing you to use it in various contexts. Additionally, the app provides options to share your LED banner directly to social media platforms or messaging apps, enabling you to effortlessly reach a wider audience.",
  "In conclusion, LED Scroller: LED Banner Maker is a powerful application that empowers users to create stunning LED banners and scrolling text. With its intuitive interface, customizable options, and range of effects, the app enables you to grab attention, promote your message, and leave a lasting impression. Whether you're a business owner, event organizer, or simply looking to add some flair to your messages, LED Scroller: LED Banner Maker is a must-have tool",
]
const PurpleSwitch = withStyles({
  switchBase: {
    color: Config.THEME_COLOR[300],
    '&$checked': {
      color: Config.THEME_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: Config.THEME_COLOR,
    },
  },
  checked: {},
  track: {
    backgroundColor: '#fff'
  },
})(Switch);

const MirrorSwitch = withStyles({
  root:{
    width: 68,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        transform: "translateX(30px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: 32,
      height: 32,
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
    },
  },
  switchBase: {
    color: Config.THEME_COLOR[300],
    '&$checked': {
      color: Config.THEME_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: Config.THEME_COLOR,
    },
  },
  checked: {},
  track: {
    backgroundColor: '#fff'
  },
})(Switch);

const FormControlLabels = withStyles({
  root:{
    margin: '0px'
  }
})(FormControlLabel);

const Home = (props) => {
  const {
    onCycleComplete,
    RGB,
    isblink,
    speed,
    name,
    setName,
    handleBlinkToggle,
    minusFonthandler,
    fontSize,
    pluseFonthandler,
    color,
    setColor,
    bgColor,
    handlBgcolor,
    handleSpeedChanger,
    handleBlinkSpeedChanger,
    handleFullScreen,
    handleShareUrl,
    blinkSpeend,
    isFlipText,
    setIsFlipText
  } = props;
  return (
    <>
      <div className="container">
        <div className=''>
          <TextSliderSection
            onCycleComplete={onCycleComplete}
            RGB={RGB}
            speed={speed}
            isblink={isblink}
            isFlipText={isFlipText}
            name={name}
            setName={setName}
          />

          <TextInputSection
            name={name}
            setName={setName}
            handleFullScreen={handleFullScreen}
            handleShareUrl={handleShareUrl}
          />

          <StaticIntroduction
            heading="Tool Introduction : -"
            metaData={metaIntroduction}
          />

          <div className='row pt-5'>
            <div className='col-12 commanUpperFontHeading'>ADJUST LED TEXT BANNER : -</div>

            <div className='row m-0 w-100 pt-4'>
              <div className='col-12 col-sm-12 col-md-9'>
                {/* <BlurEffectCom /> */}
                <div className="d-flex align-items-center w-100 stylesCotainer">
                {/* <div className="blurEffect d-flex align-items-center w-100 stylesCotainer"> */}
                  <label className="font-16 col pl-0 m-0">Text Color : </label>
                  <div className='col-auto d-flex pr-0'>
                    <label className='d-flex m-0'>
                      <div className="cp_wrapper">
                        <input type="color" name="cp_2" value={color} onChange={e => setColor(e.target.value)} />
                      </div>
                      <div className='rightIcon ml-3 d-flex align-items-center'>
                        <img
                          src={Config.images.chevronRight}
                          alt="right icon"
                          width="100%"
                          className='img-fluid'
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className='pt-4'>
                  <div className="d-flex align-items-center w-100 stylesCotainer">
                    <label className="font-16 col pl-0 m-0">Background Color : </label>
                    <label className="d-flex m-0">
                      <div className='col-auto pr-0'>
                        <div className="cp_wrapper">
                          <input type="color" name="cp_2" value={bgColor} onChange={e => handlBgcolor(e.target.value)} />
                        </div>
                      </div>
                      <div className='rightIcon ml-3 d-flex align-items-center'>
                        <img
                          src={Config.images.chevronRight}
                          alt="right icon"
                          width="100%"
                          className='img-fluid'
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className='pt-4'>
                  <div className="row m-0 align-items-center w-100 stylesCotainer">
                    <div className='col-12 col-sm-12 col-md p-0'>
                      <InputSlider
                        sliderTitle="Text Speed :"
                        selectedValue={speed}
                        handleSlideChanger={handleSpeedChanger}
                        startAdornment={<SpeedIcon />}
                        max={100}
                        step={5}
                        min={10}
                      // marks={speedArray1}
                      />
                    </div>
                  </div>
                </div>

                <div className='pt-4'>
                  <div className="d-flex align-items-center w-100 stylesCotainer">
                    <div>Blink Speed</div>
                    <div className='col-auto p-0'>
                      <FormGroup>
                        <FormControlLabels
                          control={<PurpleSwitch checked={isblink} onChange={handleBlinkToggle} name="checkedA" />}
                          // label="Custom color"
                        />
                      </FormGroup>
                    </div>
                    <div className='col pr-0'>
                      <InputSlider
                        // sliderTitle="Text Speed :"
                        selectedValue={blinkSpeend}
                        handleSlideChanger={handleBlinkSpeedChanger}
                        startAdornment={<SpeedIcon />}
                        max={-30}
                        step={5}
                        min={-1300}
                        isEnableInput={false}
                      // marks={speedArray1}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-3'>
                {/* <GoogleFixedAds
                    style="display:block; text-align:center;"
                    layout="in-article"
                    format="fluid"
                    client="ca-pub-8840218440988032"
                    slot="9687309138"
                /> */}
              </div>
            </div>
          </div>

          <div className='row pt-4'>
            <div className='col-12 col-sm-12 col-md-9'>
              {/* <div className='blurLayer'></div> */}
              <div className='stylesCotainer'>
                <div className="row m-0 align-items-center w-100 ">
                <div className='col-6 text-center'>Mirror Text</div>
                <div className='col-6 text-center'>Text Size</div>
                </div>
                <div className="row m-0 align-items-center w-100 ">
                  <div className='col-6'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <div className=''>

                        <div className='col-auto p-0'>
                          <FormGroup>
                            <FormControlLabels
                              control={<MirrorSwitch checked={isFlipText} onChange={() => setIsFlipText(!isFlipText)} name="checkedA" />}
                              // label="Custom color"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 px-0 px-md-3'>
                    <div className='col-12 col-sm-12 col-md-auto px-0 px-md-3'>

                      <div className='col volumeSettings p-0 d-flex justify-content-center align-items-center'>
                        <button type='button' className='noselect ml-0 position-relative' onClick={minusFonthandler}><div>-</div></button>
                        <div>{fontSize}</div>
                        <button type='button' className='noselect mr-0 position-relative' onClick={pluseFonthandler}><div>+</div></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row m-0 pt-4'>
                <div className="col-12 col-sm-12 col-md-12 pl-0 pr-0 pr-md-0">
                  <button className="glow-on-hover w-100" type="button" onClick={handleShareUrl}>
                    <img
                    src={Config.images.externalLink}
                    alt="right icon"
                    width="100%"
                    className='buttonIcon img-fluid'
                  />
                    Want Share!
                  </button>
                </div>
                {/* <div className='col-12 col-sm-12 col-md-6 pr-0 pl-0 pl-md-2 pt-3 pt-md-0'>
                  <button className="reOrderBtn w-100 h-100" type="button">
                  <img
                    src={Config.images.downlaodIcon}
                    alt="right icon"
                    width="100%"
                    className='buttonIcon img-fluid'
                  />
                  Recorder!
                  </button>
                </div> */}
              </div>
            </div>

            <div className='col-12 col-sm-12 col-md-3 pt-5 pt-md-0'>
              <div className='row m-0 justify-content-center socialIconSection'>
                {/* <div className='col-6 col-md-12 pr-2 pl-0 py-md-2 px-md-4'>
                  <a target="_blank" rel="noreferrer" href='https://apps.apple.com/us/app/id6443862073'>
                    <img
                      src={Config.images.appstore}
                      alt="right icon"
                      width="100%"
                      className='img-fluid'
                    />
                  </a>
                </div> */}
                <div className='col-6 col-md-12 pl-2 pr-0 py-md-2 px-md-4'>
                  <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.james.ledscroller.ledbanner.ledsign&hl=en&gl=US'>
                    <img
                      src={Config.images.playstore}
                      alt="right icon"
                      width="100%"
                      className='img-fluid'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-4'>
            <StaticIntroduction
              heading="How Does LED Scroller Work ?"
              metaData={metaDiscription}
            />
          </div>

          <div className='pt-4'>
            <StaticIntroduction
              heading="More About Application"
              metaData={moreAbout}
            />
          </div>

          <Adsense
            client="ca-pub-8840218440988032"
            format='auto'
            responsive='true'
            className="adsbygoogle"
            slot="9687309138"
            style={{ height: '300px', display:'block' }}
        />

          {/* <div className="col-md-12 righterBox pt-md-4 col-sm-12 px-3 pt-4 px-lg-3">
              <label className="font-16 col-auto pl-0 pl-lg-3">Font Size : </label>
              <input type="checkbox" defaultChecked className='toggleInput' onClick={handleBlinkToggle} id="toggle" />
              <div className="slider round" >
                <label className='' htmlFor="toggle">
                  <label className='toggleLable' htmlFor="toggle"></label>
                  <span className="on">Blink</span>
                  <span className="off">Blink</span>
                </label>
              </div>
            <div className='pt-4 w-100 pl-lg-3 pb-5'>

              <div className='pt-5'>
                <div className='pb-4'>
                  <span className='pb-2 border-bottom'>Contact Us.</span>
                </div>
                <div className='row m-0'>
                  <div className='col-12 px-1 col-sm-6'>
                    <div className='row m-0 px-0'>
                      <div className='col p-0 text-center'>
                        <MailOutlineOutlinedIcon style={{ paddingRight: '8px', fontSize: '45px' }} />
                        <a className='email-link' href="mailto:gksolution1122@gmail.com">Send your feedback</a>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 px-1 col-sm-6'>
                    <div className='row m-0 px-0'>
                      <div className='col p-0 text-center'>
                        <YouTubeIcon style={{ paddingRight: '8px', fontSize: '45px' }} />
                        LED Scroller
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row m-0'>
                <div className='col-12 px-1 col-sm-6'>
                  <div className='row m-0 px-0'>
                    <div className='col p-0 text-center'>
                      <InstagramIcon style={{paddingRight: '8px', fontSize: '45px'}}/>
                        Insta_201
                    </div>
                  </div>
                </div>
                <div className='col-12 px-1 col-sm-6'>
                  <div className='row m-0 px-0'>
                    <div className='col p-0 text-center'>
                      <FacebookIcon style={{paddingRight: '8px', fontSize: '45px'}}/>
                        Fb_201
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <style>{`
      .screenImage{
        background: url('${Config.images.screenImage}');
        height: calc(100vh);
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-size: cover;
        background-size: 100% 100%;
        background-position: center;
        flex-direction: column;
        display: flex;
        overflow: auto;
      }

      .mirrorText {
        transform: scale(-1, 1);
      }

      .marquee-container {
        overflow: hidden;
      }

      .selectOption{
        width: 100%;
        background: #282c34;
        border: 1px solid #a2a2a2;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        padding: 7px;
        /* margin-right: 13px; */
        appearance: none;
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;
        background-image: url('${Config.images.downArrow}');
      }

      .blink_me {
        animation: blinker ${Math.abs(blinkSpeend)}0ms linear infinite;
      }

      .overlayScreenImage{
        border: 5px solid #727272;
        border-radius: 8px;
        position: relative;
        height: 269px;
        background: url('${Config.images.screenImage}');
        background-repeat: repeat;
        background-attachment: inherit;
        background-size: 100%;
        background-position: center;
        overflow: auto;
        width: 100%;
        position: absolute;
        z-index: 99;
      }

      .outerBox{
        width: 100%;
        position: relative;
        height: 269px;
        color: ${color};
        background-color: ${bgColor};
        align-items: center;
        justify-content: center;
        background:${bgColor};
        flex-direction: column;
        display: flex;
        border-radius: 8px;
        overflow: auto;
        // font-size: ${isMobile ? fontSize * 2 : fontSize * 3}px;
      }

      .sliderFontSize{
        font-size: ${isMobile ? fontSize * 2.5 : fontSize * 3.3}px;
        font-weight: 900;
      }

      .marquee{
        padding-bottom: ${isMobile ? '21' : '20'}px;
      }

      @media screen and (max-width: 992px) {
        body {
          background-color: blue;
        }
      }

      @media screen and (min-width: 1240px) {
        .outerBox{
          width: 100%;
          height: 331px;
        }
        .overlayScreenImage{
          border: 5px solid #727272;
          width: 100%;
          height: 100%;
          background-size: 70%;
          border-radius: 8px;
        }
      }

      /* On screens that are 600px or less, set the background color to olive */
      @media screen and (max-width: 900px) and (orientation : landscape) {
        .outerBox{
          width: 100%;
          height: 331px;
        }
        .overlayScreenImage{
          border: 5px solid #727272;
          border-radius: 8px;
          width: 100%;
          height: 331px;
        }
      }
      `}</style>
    </>
  )
}

export default Home

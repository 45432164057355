import React, { Component } from 'react'
import * as Config from "../../lib/config";
import { isMobile } from 'react-device-detect';
import Marquee from "react-fast-marquee";
import GoogleFixedAds from '../../components/googleAds/GoogleFixedads';
// import GoogleAdsense from 'react-adsense-google';
// import {Adsense} from '@ctrl/react-adsense';
// import AdSense from 'react-adsense';
export class textSliderSection extends Component {
    // componentDidMount() {
    //     const installGoogleAds = () => {
    //       const elem = document.createElement("script");
    //       elem.src =
    //         "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    //       elem.async = true;
    //       elem.defer = true;
    //       document.body.insertBefore(elem, document.body.firstChild);
    //     };
    //     installGoogleAds();
    //     (window.adsbygoogle = window.adsbygoogle || []).push({})
    //     // (window.adsbygoogle = window.adsbygoogle || []).push({});
    //   }
  render() {
    const {
        onCycleComplete,
        RGB,
        speed,
        isblink,
        isFlipText,
        name
    } = this.props;
    return (
      <>
         <>
        <div className='row'>
            <div className='col-12 col-sm-12 col-md-12'>
                <div className="outerBox">
                    <div
                    width="100%"
                    className='overlayScreenImage'
                    ></div>
                    <Marquee width="70%" onCycleComplete={onCycleComplete} direction="left" gradient={false} gradientColor={[RGB.r, RGB.g, RGB.b]} className={`${isblink ? `blink_me` : ""} ${isFlipText ? `mirrorText` : ""}`} speed={speed * 5}>
                    {isMobile ? (
                        <div>
                        <span>{` \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`}</span>
                        <span className='sliderFontSize'>{name || "Enter Your Text"}</span>
                        </div>
                    ) : (
                        <div>
                        <span>{` \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`}</span>
                        <span className='sliderFontSize'>{name || "Enter Your Text"}</span>
                        </div>
                    )}
                    </Marquee>
                </div>
            </div>
            {/* <div className='col-12 col-sm-12 col-md-3  pt-3 pt-md-0 position-relative' style={{ height: '300px !important', cursor: 'pointer'}}>
                <a target="_blank" rel="noreferrer" href='https://apps.apple.com/sk/app/emoji-challenge-funny-filter/id6449357567'>
                    <div className='outerBox' style={{ overflow: 'hidden'}}>
                            <video width="100%" autoPlay={true} loop>
                                <source src={Config.images.emojiAppVideo} type="video/mp4"
                                />
                                Sorry, your browser doesn't support videos.
                            </video>
                    </div>
                    <div>
                        <button className='tryNoewBtn'>Try Now</button>
                    </div>
                </a>
            </div> */}
        </div>
    </>
      </>
    )
  }
}

export default textSliderSection
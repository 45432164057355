import React from 'react'
import * as Config from "../../lib/config";
const Application = () => {
  return (
    <>
        <div className='container px-0'>
          <div className='row'>
            <div className='mainContain col-6 pr-1 pr-md-2'>
              <div>
                <h1 style={{ color: '#635ee7'}}>LED Banner</h1>
                <div>
                  Simple Electronic Display App. Scrolling text is so easy.
                </div>
                <div className='pt-2'>
                  TRY App on : Google Play Or App Store
                </div>
                <div className='pt-4 col-12 col-md-7 px-0 socialIcons'>
                  <div className='row m-0 justify-content-center socialIconSection'>
                    {/* <div className='col-12 px-0 pb-3'>
                      <a target="_blank" rel="noreferrer" href='https://apps.apple.com/us/app/id6443862073'>
                        <img
                          src={Config.images.appstore}
                          alt="right icon"
                          width="100%"
                          className='img-fluid'
                        />
                      </a>
                    </div> */}
                    <div className='col-12 px-0 '>
                      <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.james.ledscroller.ledbanner.ledsign&hl=en&gl=US'>
                        <img
                          src={Config.images.playstore}
                          alt="right icon"
                          width="100%"
                          className='img-fluid'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-6 pl-1 pl-md-2'>
              <div className=''>
                <img
                    src={Config.images.applicationIcon}
                    alt="right icon"
                    width="100%"
                    className='img-fluid applicationIcon'
                />
              </div>
            </div>
          </div>
          <div className='pt-4 '>
            <div className='row m-0 justify-content-center socialIconSectionMobile'>
              {/* <div className='col-6 pr-1 pl-0 '>
                <a target="_blank" rel="noreferrer" href='https://apps.apple.com/us/app/id6443862073'>
                  <img
                    src={Config.images.appstore}
                    alt="right icon"
                    width="100%"
                    className='img-fluid'
                  />
                </a>
              </div> */}
              <div className='col-6 pl-1 pr-0 '>
                <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.james.ledscroller.ledbanner.ledsign&hl=en&gl=US'>
                  <img
                    src={Config.images.playstore}
                    alt="right icon"
                    width="100%"
                    className='img-fluid'
                  />
                </a>
              </div>
            </div>
          </div>

          <div className='deatilSection'>
            <h1>APP FEATURES</h1>
            <div>
              <h6>{`- Easy to use!`}</h6>
              <h6>{`- Custom text, text color, background color and speed`}</h6>
              <h6>{`- Different font styles`}</h6>
              <h6>{`- History of messages already used`}</h6>
              <h6>{`- Save and share your scrolling text`}</h6>
            </div>
          </div>
        </div>

        <style>{`
          .applicationIcon{
            border-radius: 10px;
          }

          .socialIconSectionMobile{
            display: none;
          }

          .mainContain{
            display: grid;
            place-content: center;
          }

          .deatilSection h1{
            color: ${Config.THEME_COLOR};
            font-size: 2rem;
            border-bottom: 5px solid ${Config.THEME_COLOR};
            border-style: double;
            border-top: none;
            border-right: none;
            border-left: none;
            width: fit-content;
            margin-bottom: 20px;
          }

          .deatilSection h6{
            padding-bottom: 1rem;
          }


          @media screen and (max-width: 740px) {
            .socialIcons{
              display: none;
            }

            .socialIconSectionMobile{
              display: flex;
            }

            .deatilSection{
              padding: 2.5rem 0px 0px 0px;
            }

            .deatilSection h1{
              color: ${Config.THEME_COLOR};
              font-size: 1.3rem;
            }

            .mainContain h1{
              font-size: 1.9rem;
            }
           
          }
        `}</style>
    </>
  )
}

export default Application

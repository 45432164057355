import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputAdornment from '@material-ui/core/InputAdornment';
import { THEME_COLOR } from "../../lib/config";

const useStyles = makeStyles({
  root: {
    width: 'calc(100% - 20px)'
  },
  input: {
    width: 77,
    color: '#fff',
    padding: '5px 0px',
  },
  grids: {
    "& .MuiInput-underline:before": {
      borderBottomColor: "#ffffff !important",
    },
  }
});

const CustomSlider = withStyles({
    root: {
      color: THEME_COLOR,
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -10,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 5,
      borderRadius: 4,
    },
    rail: {
      height: 5,
      borderRadius: 4,
      background: '#5d626d',
    },
})(Slider);

export default function InputSlider(props) {
  const { sliderTitle, startAdornment, isEnableInput = true, endAdornment, selectedValue, handleSlideChanger, max, min, step, marks } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(selectedValue);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    handleSlideChanger(newValue)
  };

  const handleInputChange = (event) => {
    if(event.target.value <= max && event.target.value >= 0){
        setValue(event.target.value === "" ? "" : Number(event.target.value));
        handleSlideChanger(parseFloat(event.target.value));
    }
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography style={{fontSize: '18px'}} id="input-slider" gutterBottom>
             {sliderTitle}
        </Typography>
        {isEnableInput && <Grid item className={classes.grids}>
          <Input
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            // onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: "number",
              "aria-labelledby": "input-slider",
              style: {
                padding: 0
              }
            }}
            startAdornment={startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
            )}
            endAdornment={endAdornment && (
                <InputAdornment position="end">
                  {endAdornment}
                </InputAdornment>
            )}
          />
        </Grid>}
      </div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs md lg>
          <CustomSlider
            value={typeof value === "number" ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={step}
            min={min}
            max={max}
            marks={marks}
          />
        </Grid>
      </Grid>
      <style>{`
      @media (pointer: coarse) {
          .MuiSlider-root {
              padding: 13px 0px !important;
          }
      }
      .MuiTypography-colorTextSecondary{
          color: #000;
      }
      `}</style>
    </div>
  );
}
